import { css, down, styled, thd } from '@smooth-ui/core-sc';

export default styled.nav`
  transition: all 0.3s ease 0s;

  ${down(
    'lg',
    css`
      background-color: ${thd('customMenuBackground', '#fff')};
      height: 100vh;
      overflow-y: scroll;
      position: fixed;
      right: ${props => (props.openMobileMenu ? '0' : '-240px')};
      top: 0;
      visibility: ${props => (props.openMobileMenu ? 'visible' : 'hidden')};
      width: 240px;
      z-index: 100;
    `
  )};
`;
