/**
 * Button
 */
import PropTypes from 'prop-types';
import { Box, Button, controlFocus, styled, th } from '@smooth-ui/core-sc';

// Button Block
export const ButtonBlock = styled(Box)`
  margin-top: ${props => props.theme.buttonBlockSpacerTop || '2rem'};
  margin-bottom: ${props => props.theme.buttonBlockSpacerBottom || '1rem'};

  ${props =>
    !!props.theme.buttonBlockHasSeparator
      ? `
        padding-top: ${props.theme.buttonBlockSpacerInner || '1.5rem'};
        border-top: 1px solid ${props.theme.buttonSeparatorColor || '#dee2e6'};
        `
      : null};
`;

const StyledButton = styled(Button)`
  ${props => props.disabled && `cursor: not-allowed;`}
  ${props =>
    props.theme.customInputBtnFontWeight &&
    `font-weight: ${props.theme.customInputBtnFontWeight};`}
  margin-top: 1px;
  min-width: ${props => props.minWidth || props.theme.inputBtnMinWidth};
  text-align: center;

  &:focus {
    ${p => controlFocus(th(p.variant)(p))(p)}
  }
`;

StyledButton.propTypes = {
  disabled: PropTypes.bool,
  minWidth: PropTypes.string,
  theme: PropTypes.object,
};
export default StyledButton;
