import { css, styled, up } from '@smooth-ui/core-sc';

export default styled.ul`
  background-color: ${props =>
    props.theme.customSubMenuBackground ||
    props.theme.customHeaderBackground ||
    '#fff'};
  display: block;
  list-style: none;
  margin: 0;
  min-width: 180px;
  padding: 0;

  ${up(
    'lg',
    css`
      box-shadow: rgba(0, 0, 0, 0.15) 0px 0.25rem 2rem;
      text-align: left;
      ${props =>
        props.depth === 2 &&
        css`
          position: absolute;
          top: 100%;
          z-index: 100;
        `}

      li {
        position: relative;
      }
    `
  )}
`;
