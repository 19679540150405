import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { styled, withTheme, up, css, thd } from '@smooth-ui/core-sc';
import Button from '_platform/src/components/Button/Button';
import FocusLock from 'react-focus-lock';
import { CloseCircle } from 'styled-icons/remix-fill';
import LoadAsync from '_platform/src/utils/LoadAsync';

const LoginProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginProvider" */ '../../../containers/LoginProvider/LoginProvider'
  )
);
const LoginForm = LoadAsync(() =>
  import(/* webpackChunkName: "loginForm" */ '../../LoginForm/LoginForm')
);

const Block = styled.div`
  text-align: right;
  display: inline-block;

  ${up(
    'md',
    css`
      padding-top: 0.5rem;
    `
  )};

  ${up(
    'lg',
    css`
      padding-top: 1rem;
    `
  )};
`;

const LoginButton = styled(Button)`
  border-radius: 100em;
  color: #fff;
  padding: 6px 25px;
  margin-left: 5px;
  margin-right: 5px;

  &:visited {
    color: #fff;
  }
`;

const RegisterButton = styled(Button)`
  background-color: #636466;
  border-radius: 100em;
  color: #fff;
  padding: 6px 25px;
  margin-left: 5px;
  margin-right: 5px;

  &:visited {
    color: #fff;
  }
`;

const LoginContainer = styled.div`
  text-align: left;
  padding: 25px;

  .reset-password a {
    color: #18a950;
    &:visited {
      color: #18a950;
    }
    &:focus,
    &:hover {
      color: #636466;
    }
  }

  hr {
    border-bottom: 1px solid #867455;
  }

  label {
    font-weight: bold;
  }

  button {
    border-radius: 100em;
    color: #fff;
    min-width: 180px;
    padding: 6px 25px;
    margin-left: 5px;
    margin-right: 5px;

    &:visited {
      color: #fff;
    }
  }
`;

const QuickviewContainer = styled.div`
  .quickview-close-container {
    text-align: right;
  }
  .quickview-close {
    background: transparent;
    border: 0 none;
    color: ${thd('quickviewColor', thd('defaultFontColor', '#555'))};
    height: 30px; /* Must-have for IE11 */
    margin-right: 0.5rem;
    margin-top: 0.25em;
    //opacity: 0.5;
    padding: 3px;
    text-align: right;
    width: 30px;
  }

  .quickview-login {
    /* Hack to get around Safari position:fixed bug, removes animation */
    display: ${p => (p.isQuickviewActive ? 'block' : 'none')};
    background-color: ${thd('quickviewBackground', '#fff')};
    border-left: 1px solid ${thd('borderColor', '#ddd')};
    color: ${thd('quickviewColor', thd('defaultFontColor', '#555'))};
    height: 100vh;
    max-width: 90vw;
    overflow-y: auto;
    position: fixed;
    right: ${p => (p.isQuickviewActive ? '0' : '-500px')};
    top: 0;
    transition: all 0.3s ease 0s;
    width: 600px;
    z-index: 1000;

    ${p =>
      p.isQuickviewActive &&
      css`
        box-shadow: rgba(0, 0, 0, 0.15) 0px 0.25rem 2rem;
      `}
  }

  .quickview-overlay {
    background-color: ${thd('quickviewBackground', '#fff')};
    bottom: 0;
    left: 0;
    opacity: ${p => (p.isQuickviewActive ? '0.85' : '0')};
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.3s ease 0s;
    visibility: ${p => (p.isQuickviewActive ? 'visible' : 'hidden')};
    z-index: 999;
  }

  .quickview-product-container {
    padding: 10px;

    .quickview-brand-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100px;
      height: 73px;

      .quickview-brand-image {
        max-width: 93px;
        max-height: 67px;
        width: auto;
        height: auto;
      }
    }
  }

  .quickview-product-image-container {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .quickview-image-container {
    width: 325px;
    height: 325px;

    .quickview-image {
      max-height: 310px;
      max-width: 310px;
      width: auto;
      height: auto;
    }
  }

  .quickview-code {
    color: #fff;
    font-size: 16px;
    width: 60px;
    padding: 5px 10px;
    text-align: center;
  }
`;

const HeaderLoginNav = () => {
  const [quickviewLogin, setQuickviewLogin] = useState(null);
  const toggleQuickview = value => {
    setQuickviewLogin(value);
  };

  return (
    <div>
      <QuickviewContainer
        className="quickview-container"
        isQuickviewActive={Boolean(quickviewLogin)}
      >
        <div className="quickview-login">
          {quickviewLogin && (
            <FocusLock>
              <div className="quickview-close-container">
                <button
                  className="quickview-close"
                  onClick={() => toggleQuickview(false)}
                  onKeyUp={
                    event =>
                      event.keyCode === 27 &&
                      toggleQuickview(false) /* escape */
                  }
                  tabIndex={0}
                  type="button"
                >
                  <CloseCircle fill="#18a950" />
                </button>
              </div>
              <LoginContainer>
                <h1 className="text--primary">Welcome back</h1>
                <LoginProvider disableMeta>
                  <LoginForm
                    formLabels={{
                      labelUserName: 'Email',
                      labelRememberMe: 'Remember login',
                    }}
                  />
                </LoginProvider>
                <hr />
                <span>
                  <strong>Don't have an account?</strong>
                  <RegisterButton
                    as={Link}
                    to="/register/"
                    onClick={() => toggleQuickview(false)}
                  >
                    Register
                  </RegisterButton>
                </span>
              </LoginContainer>
            </FocusLock>
          )}
        </div>
        {/* Keyboard event is handled on the button (enter/space/escape) */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className="quickview-overlay"
          onClick={() => toggleQuickview(false)}
        />
      </QuickviewContainer>
      <Block>
        <LoginButton onClick={() => toggleQuickview(true)}>Login</LoginButton>
        <RegisterButton as={Link} to="/register/">
          Register
        </RegisterButton>
      </Block>
    </div>
  );
};

export default withTheme(HeaderLoginNav);
